import { Component, OnInit, OnDestroy, AfterContentChecked, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import config from '../services/config';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { ChangeTitleNav, ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReviewComponent } from '../bookin/review/review.component';
import { TemplateLiteral } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  bookings = [];
  today = new Date();
  time_zone = "";
  uiSub: Subscription = new Subscription();
  isLoading = false;
  minDate = (new Date().getTime()) - 5400000;
  maxDate = new Date().getTime() + (12 * 60 * 60 * 1000);

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private cdRef:ChangeDetectorRef) {
    this.uiSub = store.select('ui').subscribe(d => {
      this.isLoading = d.isLoading;
    })
  }

  ngOnInit() {
    // console.log((this.minDate));
    Promise.resolve().then(() => {
      this.store.dispatch(new ChangeTitleNav("Citas para hoy"));
      this.cdRef.detectChanges();
    });
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.authService.user.time_zone ? this.authService.user.time_zone : resolvedOptions.timeZone;
    this.getBookings("today");
    this.getBookingsMH("today");
    console.log(this.getBookings("today"))
  }

  putImg() {
    this.bookings.forEach((elem, index) => {
      let img = elem.patient_user_id.photo_media_id;
      if (typeof img === 'number') {
        this.authService.getImgId(img).subscribe((d: any) => {
          this.bookings[index].patient_user_id.photo_media_id = d.data.items[0].url
        });
      }
    });
  }

  getBookings(type) {

    console.log(new Date(this.minDate));
    let maxDate = new Date().getTime() + (24 * 60 * 60 * 1000);
    this.store.dispatch(new ActivateLoadingAction());

    this.authService.datesScheduled(this.authService.user.id).subscribe((d: any) => {
      // decrypt
      let decData = JSON.parse(this.authService.decrypt(d.message, 'private'));
      let bookings = decData.data.items.filter(elem => elem.start_at < maxDate);
      console.log(bookings)
      this.bookings = bookings.map(elem => {
        return {
          ...elem,
          start_at: momentTZ(elem.start_at).tz(this.time_zone).format(),
        };
      })


      this.authService.datesInProgress(this.authService.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.authService.decrypt(d.message, 'private'));

        this.store.dispatch(new DeactivateLoadingAction());

        let bookings = decData.data.items.map(elem => {
          return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
        })
        this.bookings = [...bookings, ...this.bookings];
        // this.putImg()
      }, err => {
        this.store.dispatch(new DeactivateLoadingAction());
        console.log(err)
      });

    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }

  getBookingsMH(type) {

    console.log(new Date(this.minDate));
    let maxDate = new Date().getTime() + (24 * 60 * 60 * 1000);
    this.store.dispatch(new ActivateLoadingAction());

    this.authService.datesScheduledMH(this.authService.user.id).subscribe((d: any) => {
      // decrypt
      let decData = JSON.parse(this.authService.decrypt(d.message, 'private'));
      let bookings = decData.data.items.filter(elem => elem.start_at < maxDate);
      console.log(bookings)
      this.bookings = bookings.map(elem => {
        return {
          ...elem,
          start_at: momentTZ(elem.start_at).tz(this.time_zone).format(),
        };
      })


      this.authService.datesInProgress(this.authService.user.id).subscribe((d: any) => {
        let decData = JSON.parse(this.authService.decrypt(d.message, 'private'));

        this.store.dispatch(new DeactivateLoadingAction());

        let bookings = decData.data.items.map(elem => {
          return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
        })
        this.bookings = [...bookings, ...this.bookings];
        // this.putImg()
      }, err => {
        this.store.dispatch(new DeactivateLoadingAction());
        console.log(err)
      });

    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }

  cheackDates(date: string = "") {
    // console.log(date)
    let d = new Date(date);
    let tz = momentTZ.tz(d, "America/Mexico_City").clone().tz("America/Mexico_City").format();
    // console.log(tz)
    let tzDay = momentTZ.tz(this.today, "America/Mexico_City").format();

    return !((Date.parse(tz) - 300000) <= Date.parse(tzDay));
  }

  goSession(b) {

    this.authService.conectDate(b.doctor_user_id.id, b.id).subscribe((d: any) => {
      let decData = JSON.parse(this.authService.decrypt(d.message, 'private'));
      console.log(decData.data.items[0])
      config.SESSION_ID = decData.data.items[0].conference_session_token;
      config.TOKEN = decData.data.items[0].token;
      console.log(config)
      this.router.navigate(['/panel/video', b.id]);
    })
  }

  goChat(booking) {
    this.router.navigate(['/panel/chat']);
  }

  ngOnDestroy() {
    this.uiSub.unsubscribe();
  }

  returnStatus(s) {
    let status = s;
    switch (s) {
      case 'scheduled_appointment':
        status = "Cita Agendada";
        break;
      case 'appointment_in_progress':
        status = "En progreso"
        break;
      default:
        status = s;
        break;
    }

    return status

  }

  refesh() {
    this.getBookings("today");
  }

  showSession(date): boolean {
    return this.minDate > new Date(date).getTime();
  }

  closeSession(b) {
    console.log(b);
    const dialogRef = this.dialog.open(ReviewComponent, {
      width: '500px',
      height: '500px',
      data: { id: b.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.refesh();
    });

  }

}
