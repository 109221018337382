// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-app {
  margin-top: 65px;
}

.top-space {
  /*margin-top:62px*/
  margin-top: 0px;
  text-align: center;
}

img {
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/panel/panel.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".content-app{\r\n    margin-top: 65px;\r\n}\r\n\r\n.top-space{\r\n    /*margin-top:62px*/\r\n    margin-top: 0px;\r\n    text-align: center;\r\n}\r\n\r\nimg{\r\n    height: 52px;\r\n    margin-top: 5px;\r\n    margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
