// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.btn-container button {
  width: 80%;
}

.add-btn {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
}

.home-btn {
  position: fixed;
  top: 2px;
  right: 15px;
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/app/horarios/horarios.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAAI;EACI,UAAA;AAER;;AAEA;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;AACJ","sourcesContent":[".btn-container{\r\n    position: fixed;\r\n    bottom: 10px;\r\n    left: 0;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    z-index: 9999;\r\n    button{\r\n        width: 80%;\r\n    }\r\n}\r\n\r\n.add-btn{\r\n    position: fixed;\r\n    bottom: 15px;\r\n    right: 15px;\r\n    z-index: 999;\r\n}\r\n\r\n.home-btn{\r\n    position: fixed;\r\n    top: 2px;\r\n    right: 15px;\r\n    z-index: 9999;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
