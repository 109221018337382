// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  width: 100px;
  height: 100px;
}

.content-info {
  align-items: baseline;
}`, "",{"version":3,"sources":["webpack://./src/app/horarios/appointment-details/appointment-details.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;;AAEE;EACE,qBAAA;AACJ","sourcesContent":[".avatar{\r\n    width: 100px;\r\n    height: 100px;\r\n  }\r\n\r\n  .content-info{\r\n    align-items: baseline;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
