// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/app/bookin/video-chat/subscriber/subscriber.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,wBAAA;EACA,gBAAA;EACA,WAAA;AACJ","sourcesContent":["div{\r\n    width: 100%!important;\r\n    height: 100vh!important;\r\n    overflow: hidden;\r\n    z-index: -1;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
