import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-out-office',
  templateUrl: './out-office.component.html',
  styleUrls: ['./out-office.component.scss']
})
export class OutOfficeComponent implements OnInit {

  addNew = false;
  hoursI = [0,1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  hoursF = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  name= "";
  inicio = 7;
  fin = 24;
  dia = new Date();

  slots = [];

  outsOfOffice = [];

  constructor(
    public dialogRef: MatDialogRef<OutOfficeComponent>,
    private service: AuthService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.service.getOutOffice().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      const outOfficeData = decData.data.items;

      this.service.getOutOfficeMH().subscribe((d: any) => {
        let decDataMH = JSON.parse(this.service.decrypt(d.message, 'private'));
        const outOfficeMHData = decDataMH.data.items;
        
        const combinedData = this.combineAndGroupByDate(outOfficeData, outOfficeMHData);
        this.outsOfOffice = combinedData;
        console.log(this.outsOfOffice);
      });
    });
  }
  
  combineAndGroupByDate(data1: any[], data2: any[]): any[] {
    const combinedData = [];  
    const allData = data1.concat(data2);  
    const groupedByDate = {};
    allData.forEach(item => {
      const date = new Date(item.start_at).toDateString();
      if (!groupedByDate[date]) {
        groupedByDate[date] = [];
      }
      groupedByDate[date].push(item);
    });
  
    // Crear los objetos combinados
    for (const date in groupedByDate) {
      if (groupedByDate.hasOwnProperty(date)) {
        const items = groupedByDate[date];
        if (items.length > 1) {
          const combinedItem = {
            created_at: items[0].created_at, 
            deleted: items[0].deleted,
            description: items[0].description,
            doctor_user_id: items[0].doctor_user_id, 
            end_at: items[0].end_at,
            start_at: items[0].start_at,
            updated_at: items[0].updated_at, 
            id_gnp: items[0].id, 
            id_mh: items[1].id
          };
          combinedData.push(combinedItem);
        }
      }
    }
  
    return combinedData;
  }
  
  

  saveDay() {

    let day = moment(this.dia).format("MM-DD-YYYY");
    let start = `${day} ${this.inicio}:00:00`;
    let end = `${day} ${this.fin}:00:00`;


    let data = {
      description: this.name,
      start_at: moment(start).utc().format('x'),
      end_at: moment(end).utc().format('x')
    }

    let encData = { message: this.service.encrypt(data,"private") };

    console.log(data);
    this.service.setOutOffice(encData).subscribe (d =>{
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: "Tus horarios fuera de oficina ya han sido actualizados.",
      });
      console.log("GNP")
    },(res)=>{
      res = this.service.decrypt(res.error.message,'private')
    })
    this.service.setOutOfficeMH(encData).subscribe (d =>{
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: "Tus horarios fuera de oficina ya han sido actualizados.",
      });
      console.log("MH")
      this.dialogRef.close(false);
    },(res)=>{
      res = this.service.decrypt(res.error.message,'private')
    })
  }

  deleteOutOfOffice(row:any){
    Swal.fire({
      icon:'warning',
      title:'¿Esta seguro?',
      text:'Borrar este horario de fuera de oficina',
      showCancelButton:true,
      confirmButtonText:'Si',
      cancelButtonText:'No, salir.',
      reverseButtons:true
    }).then((res) =>{
      console.log(res)
      if(res.isConfirmed){
        this.service.deleteOutOffice(row.id_gnp).subscribe((d:any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
          Swal.close();
          this.service.getOutOffice().subscribe( (d:any) =>{
            let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
            this.outsOfOffice = decData.data.items;
          } );
        })
        this.service.deleteOutOfficeMH(row.id_mh).subscribe((d:any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
          console.log("se borro")
          Swal.close();
          this.service.getOutOfficeMH().subscribe( (d:any) =>{
            let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
            this.outsOfOffice = decData.data.items;
          });
        }, (error: any) => {
          console.log(error)
            let decError = this.service.decrypt(error.error.message, 'private')
            console.log(decError)
            console.error("Error al obtener los horarios de fuera de oficina:", error);
        });
      }
    });
  }
  

      
      
  }


