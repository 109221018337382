// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #111;
  z-index: 999;
}

.doctor-camera {
  position: absolute;
  width: 80%;
  height: 90vh;
  top: 0;
  right: 190px;
}

.buttons-container {
  position: absolute;
  width: 100%;
  bottom: 100px;
  z-index: 999;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/bookin/video-chat/video-chat.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,MAAA;EACA,YAAA;AACJ;;AAGA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AAAJ","sourcesContent":[".video-container{\r\n    position: fixed;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: #111;\r\n    z-index: 999;\r\n}\r\n\r\n.doctor-camera{\r\n    position: absolute;\r\n    width: 80%;\r\n    height: 90vh;\r\n    top: 0;\r\n    right: 190px;\r\n\r\n}\r\n\r\n.buttons-container{\r\n    position: absolute;\r\n    width: 100%;\r\n    bottom: 100px;\r\n    z-index: 999;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
