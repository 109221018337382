import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { HourSelectComponent } from './hour-select/hour-select.component';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';

import { Subject, Subscription } from 'rxjs';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { OutOfficeComponent } from './out-office/out-office.component';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';

const colors: any = {
  red: {
    primary: '#BEBEBE',
    secondary: '#F1F1F1',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#9cbcbc',
    secondary: '#9cbcbc',
  },
  gray: {
    primary: '#cccccc',
    secondary: '#cccccc',
  },
};

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss']
})

export class HorariosComponent implements OnInit, OnDestroy {

  time_zone = "";
  timeSlots = [];
  locale:string = 'es';

  // redux
  uiSub: Subscription = new Subscription();
  isLoading = false;


  view: CalendarView = CalendarView.Week; // or an initial value

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
  ];

  activeDayIsOpen: boolean = true;


  constructor(private service: AuthService, public dialog: MatDialog, private store: Store<AppState>) {
    this.uiSub = store.select('ui').subscribe(d => {
      this.isLoading = d.isLoading;
    })
  }

  // Calendar View

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // open modal
    const dialogRef = this.dialog.open(AppointmentDetailsComponent, {
      width: '500px',
      height: '500px',
      data: { event }
    });

  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
    this.getTimeSlots();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.getTimeSlots();
  }
  // End Calendar View

  ngOnInit() {

    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.service.user.time_zone ? this.service.user.time_zone : resolvedOptions.timeZone;
    // console.log('El nombre de tu zona horaria es ', resolvedOptions.timeZone);
    this.view = CalendarView.Week
    this.getTimeSlots();

  }

  getTimeSlots() {
    this.store.dispatch(new ActivateLoadingAction());

    let day = moment.utc(this.viewDate).format('x');

    // Arreglo para almacenar eventos únicos
    let uniqueEvents = [];

    // Función para agregar un evento al arreglo de eventos únicos, priorizando citas agendadas
    const addUniqueEvent = (event) => {
        const existingIndex = uniqueEvents.findIndex(e => e.start.getTime() === event.start.getTime() && e.end.getTime() === event.end.getTime());
        
        if (existingIndex === -1 || event.title === 'Cita agendada GNP' || event.title === 'Cita agendada MH') {
            if (existingIndex !== -1) {
                uniqueEvents.splice(existingIndex, 1); // Eliminar evento duplicado
            }
            uniqueEvents.push(event); // Agregar evento único
        }
    };

    // Llamada al primer endpoint
    this.service.getHours(this.service.user.id, day, this.view).subscribe((data: any) => {
        let decUser = JSON.parse(this.service.decrypt(data.message,"public"));

        if (decUser.data.items.length !== 0) {
            decUser.data.items.forEach(element => {
                element.availability.forEach(elem => {
                    let minutes = elem.minutes == 30 ? "30" : "00";
                    let start = moment(elem.start_at_miliseconds_utc).format()
                    let plushour = moment(elem.start_at_miliseconds_utc).add(1, 'hour').format();
                    let end = moment(plushour).format()

                    let event = {
                        start: new Date(start),
                        end: new Date(end),
                        title: elem.slot_type === 'availability' ? 'Disponible' : elem.slot_type === 'out_of_office' ? 'Fuera de oficina' : 'Cita agendada GNP',
                        color: elem.slot_type === 'availability' ? colors.gray : elem.slot_type === 'out_of_office' ? colors.gray : colors.blue, // Cambiado el color a gris si es "Disponible"
                        actions: this.actions,
                        allDay: false,
                        data: elem.payload,
                        resizable: {
                            beforeStart: false,
                            afterEnd: false,
                        },
                        draggable: false,
                    };


                   addUniqueEvent(event);
                });
            });
        } else {
            console.log("No time slots from first endpoint")
        }

        this.service.getHoursMH(this.service.user.id, day, this.view).subscribe((otherData: any) => {
            let otherDecUser = JSON.parse(this.service.decrypt(otherData.message,"public"));

            if (otherDecUser.data.items.length !== 0) {
                otherDecUser.data.items.forEach(element => {
                    element.availability.forEach(elem => {
                        let minutes = elem.minutes == 30 ? "30" : "00";
                        let start = moment(elem.start_at_miliseconds_utc).format()
                        let plushour = moment(elem.start_at_miliseconds_utc).add(1, 'hour').format();
                        let end = moment(plushour).format()

                        let event = {
                            start: new Date(start),
                            end: new Date(end),
                            title: elem.slot_type === 'availability' ? 'Disponible' : elem.slot_type === 'out_of_office' ? 'Fuera de oficina' : 'Cita agendada MH',
                            color: elem.slot_type === 'availability' ? colors.gray : elem.slot_type === 'out_of_office' ? colors.gray : colors.green, // Cambiado el color a gris si es "Disponible"
                            actions: this.actions,
                            allDay: false,
                            data: elem.payload,
                            resizable: {
                                beforeStart: false,
                                afterEnd: false,
                            },
                            draggable: false,
                        };


                        //addUniqueEvent(event);
                    });
                });
            } else {
                console.log("No time slots from second endpoint")
            }

            this.events = uniqueEvents; 
            this.store.dispatch(new DeactivateLoadingAction());
        }, err => {
            console.error("Error fetching data from second endpoint:", err);
            this.store.dispatch(new DeactivateLoadingAction());
        });
        
    }, err => {
        console.error("Error fetching data from first endpoint:", err);
        this.store.dispatch(new DeactivateLoadingAction());
    });
}




  openDialog(type): void {
    const dialogRef = this.dialog.open(HourSelectComponent, {
      width: '1500px',
      height: '800px',
      data: { type }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTimeSlots();
    });
  }

  openWork(): void {
    const dialogOut = this.dialog.open(OutOfficeComponent, {
      width: '1500px',
      height: '800px',
    });

    dialogOut.afterClosed().subscribe(result => {
      this.getTimeSlots();
    });
  }

  formatDate(date, time) {
    return date
  }

  ngOnDestroy() {
    this.uiSub.unsubscribe();
  }

}


