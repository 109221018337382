// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-edit {
  position: relative;
}

.img-container {
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.edit-btn {
  position: absolute;
  top: 10px;
  right: 35%;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/app/settings/edit-profile/edit-profile.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AACJ","sourcesContent":[".img-edit{\r\n    position: relative;\r\n}\r\n\r\n.img-container{\r\n    width: 250px;\r\n    height: 250px;\r\n    overflow: hidden;\r\n    position: relative;\r\n    border-radius: 50%;\r\n}\r\n\r\n.edit-btn{\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 35%;\r\n    z-index: 999;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
