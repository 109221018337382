// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  width: 100px;
  height: 100px;
}

.content-info {
  align-items: baseline;
}

.get-more {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/bookin-mh/bookin-mh.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".avatar{\n    width: 100px;\n    height: 100px;\n}\n\n.content-info{\n    align-items: baseline;\n}\n\n.get-more{\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
