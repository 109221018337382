// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  flex-flow: column;
}

.login-box {
  width: 500px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .login-box {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAEA;EACI;IACI,WAAA;IACA,aAAA;IACA,iBAAA;IACA,uBAAA;IACA,mBAAA;IACA,cAAA;EACN;AACF","sourcesContent":[".login-container{\r\n    display: flex;\r\n    flex-flow: column;\r\n}\r\n\r\n.login-box{\r\n    width: 500px;\r\n    display: flex;\r\n    flex-flow: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 0 auto;\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n    .login-box{\r\n        width: 100%;\r\n        display: flex;\r\n        flex-flow: column;\r\n        justify-content: center;\r\n        align-items: center;\r\n        margin: 0 auto;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
