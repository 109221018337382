// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  width: 150px;
}

section {
  min-height: 93vh;
}

.s-box {
  height: 100%;
  justify-content: space-around;
}

.info-container {
  align-items: center;
  flex-flow: column;
  overflow: hidden;
}

.active-link {
  border: 1px solid gray;
}`, "",{"version":3,"sources":["webpack://./src/app/settings/settings.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,YAAA;EACA,6BAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[".avatar{\r\n    width: 150px;\r\n}\r\n\r\nsection{\r\n    min-height: 93vh;\r\n}\r\n\r\n.s-box{\r\n    height: 100%;\r\n    justify-content: space-around;\r\n}\r\n\r\n.info-container{\r\n    align-items: center;\r\n    flex-flow: column;\r\n    overflow: hidden;\r\n}\r\n\r\n.active-link{\r\n    border: 1px solid gray;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
