import { Component, OnInit, AfterContentChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import config from '../services/config';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DeclineModalComponent } from '../ui/decline-modal/decline-modal.component';
import { ChatService } from '../services/chat.service';
import { OnesignalService } from '../services/onesignal.service';
import * as momentTZ from 'moment-timezone';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { ChangeTitleNav, ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import { ReBokingComponent } from './re-boking/re-boking.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Refresh } from '@ngrx/store-devtools/src/actions';


@Component({
  selector: 'app-bookin',
  templateUrl: './bookin.component.html',
  styleUrls: ['./bookin.component.scss']
})

export class BookinComponent implements OnInit, AfterContentChecked, OnDestroy {

  bookingsToConfirm = [];
  bookingsUpcoming = [];
  bookingsPast = [];
  bookingsCancel = [];
  today = new Date();
  time_zone = "";
  // redux
  uiSub: Subscription = new Subscription();
  isLoading = false;
  index = 0;
  showGetMore = true;

  constructor(
    private service: AuthService,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private chatService: ChatService,
    private osService: OnesignalService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef) {
    this.uiSub = store.select('ui').subscribe(d => {
      this.isLoading = d.isLoading;
    })
  }

  ngOnInit() {
    Promise.resolve().then(() => {
      this.store.dispatch(new ChangeTitleNav("Citas"));
      this.cdRef.detectChanges();
    });
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.service.user.time_zone ? this.service.user.time_zone : resolvedOptions.timeZone;
    this.refresh();
    /*this.getBookingsToConfirm();
    this.getBookingsUpcoming();
    this.getBookingsPast();*/
  }

  ngAfterContentChecked() {
    // this.bookingsPast.forEach( (elem, index) =>{
    //   let img = elem.patient_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsPast[index].patient_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // });
    // this.bookingsUpcoming.forEach( (elem, index) =>{
    //   let img = elem.patient_user_id.photo_media_id;
    //   if( typeof img === 'number' ){
    //     this.service.getImgId(img).subscribe((d: any) => {
    //       this.bookingsUpcoming[index].patient_user_id.photo_media_id = d.data.items[0].url
    //     });
    //   }
    // });
  }

  changeTab($event) {
    this.index = $event.index;
    switch (this.index) {
      case 0: if (this.bookingsToConfirm.length == 0) this.getBookingsToConfirm(); break;
      case 1: if (this.bookingsUpcoming.length == 0) this.getBookingsUpcoming(); break;
      case 2: if (this.bookingsPast.length == 0) this.getBookingsPast(); break;
      case 3: if (this.bookingsCancel.length == 0) this.getBookingsToCancel(); break;
    }
  }

  getBookingsToConfirm() {
    this.store.dispatch(new ActivateLoadingAction());
    this.service.datesToConfirm(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      //console.log(decData.data.items);
      let bookings = decData.data.items;
      this.bookingsToConfirm = bookings.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      });
      this.store.dispatch(new DeactivateLoadingAction());

    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
      let decData = JSON.parse(this.service.decrypt(err.error.message, 'private'));
      console.log(decData);
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });

  }


  getBookingsUpcoming() {
    this.store.dispatch(new ActivateLoadingAction());
    this.service.datesScheduled(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      //console.log(decData.data.items)
      let bookings = decData.data.items;
      this.bookingsUpcoming = bookings.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      })
      this.store.dispatch(new DeactivateLoadingAction());
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });

  }

  getBookingsToCancel() {
    this.store.dispatch(new ActivateLoadingAction());
    this.service.datesToCancel(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      //console.log(decData.data.items)
      let bookings = decData.data.items;
      this.bookingsCancel = bookings.map(elem => {
        return { ...elem, start_at: momentTZ(elem.start_at).tz(this.time_zone).format() };
      })
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(bookings)
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }
  cancelAppointment(b) {
    this.showCancelConfirmation().then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(new ActivateLoadingAction());
        this.service.cancelApointment(b.id).subscribe(
          (response) => {
            console.log('Respuesta exitosa:', response);
            this.store.dispatch(new DeactivateLoadingAction());
            this.getBookingsToCancel()
          },
          (error) => {
            console.error('Error al cancelar cita:', error);
            this.store.dispatch(new DeactivateLoadingAction());
          }
        );
      }
    });
  }

  showCancelConfirmation() {
    return Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres cancelar esta cita?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, cancelar cita',
      cancelButtonText: 'Cerrar',
    });
  }

  getBookingsPast(/*type*/) {
    /*let data = {
      type,
      time_zone: 'America/Regina'
    }*/
    this.store.dispatch(new ActivateLoadingAction()); this.isLoading = true;
    this.service.endDatesFinish(10, this.bookingsPast.length).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      //console.log(decData.data.items)
      //if(this)
      if (decData.data.items.length == 0) this.showGetMore = false;
      decData.data.items.forEach(item => {
        this.bookingsPast.push(item)
      });
      //this.bookingsPast = decData.data.items;
      this.store.dispatch(new DeactivateLoadingAction()); this.isLoading = false;
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction()); this.isLoading = false;
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.msg,
      });
    });
  }

  cheackDates(date: string = "") {
    return !(this.today >= new Date(date));
  }

  goSession() {
    this.router.navigate(['/panel/video']);
  }

  acceptBooking(status, date) {
    //console.log(date)
    if (status == 'declined') {
      this.service.rejectDate(date.doctor_user_id.id, date.id).subscribe(d => {
        this.getBookingsToConfirm();
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          text: err.error.error.errors[0].message,
        });
      });

    } else {
      this.service.acceptDate(date.doctor_user_id.id, date.id).subscribe(d => {
        this.getBookingsToConfirm();
        this.getBookingsUpcoming();
        Swal.fire({
          icon: 'success',
          title: 'Confirmada!',
          text: 'Cita confirmada.',
        });
      }, err => {
        //let error = err.error.error.errors[0];
        let error = JSON.parse(this.service.decrypt(err.error.message, 'private'));

        console.log("Error", error)

        if (error.statusText === "not found" || error.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: "La cita no ha sido no encontrada.",
          });
        } else {
          if (error == 'Not payment_source selected, please create a new appointment') {
            error = "La tarjeta del usuario ha sido rechazada, la cita sera cancelada"
          }/*else{//Se comento para analizar el error que genera - GCG 29062022
          error = "El cupón del usuario es invalido favor de cancelar la cita"
        }*/

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ha ocurrido un error al aceptar la cita.',
          });
        }
        this.getBookingsToConfirm();
        this.getBookingsUpcoming();
      });
    }
  }

  goChat(booking) {
    this.router.navigate(['/panel/chat']);
  }

  refresh() {
    switch (this.index) {
      case 0: this.getBookingsToConfirm(); break;
      case 1: this.getBookingsUpcoming(); break;
      case 2: this.showGetMore = true; this.bookingsPast = []; this.getBookingsPast(); break;
      case 3: this.getBookingsToCancel(); break;
    }
  }
  ngOnDestroy() {
    this.uiSub.unsubscribe();
  }

  reDateBooking(doc) {
    console.log(doc)
    const dialogRef = this.dialog.open(ReBokingComponent, {
      width: '800px',
      height: '600px',
      data: doc
    });
  }

}
