import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {

  isDate = false;
  user = {
    appointment_cancelled_by: "",
    base_price: 150,
    conference_session_token: "",
    created_at: 1590435868036,
    deleted: false,
    doctor_connected: false,
    doctor_user_id: {},
    end_at: 1590444000000,
    final_price: 150,
    id: 1,
    patient_connected: false,
    patient_user_id: { photo_media_id: "", first_name: "", last_name: "" },
    promo_code: null,
    review: null,
    start_at: 1590440400000,
    status: "scheduled_appointment",
    updated_at: 1590435884698,
  }

  isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<AppointmentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private service: AuthService) { }

  ngOnInit() {
    console.log(this.data.event.data);
    this.isDate = this.data.event.data != undefined;
    if (this.isDate) {
      if(this.data.event.title == 'out_of_office' || this.data.event.title == 'Fuera de oficina'){
        this.isLoading = true;
        this.user = {
          appointment_cancelled_by: "", base_price: 0,
          conference_session_token: "", created_at: this.data.event.data.created_at,
          deleted: false,doctor_connected: false,
          doctor_user_id: {},end_at: this.data.event.data.end_at,
          final_price: 0,id: this.data.event.data.id,
          patient_connected: false, patient_user_id: {
              photo_media_id: 'https://terapeuta.mindhelp.mx/assets/img/profile-ooo.jpg',
              first_name: this.data.event.data.description, last_name: "" },
          promo_code: null,review: null,start_at: this.data.event.data.start_at,
          status: "out_of_office", updated_at: this.data.event.data.updated_at,
        };
      }else{
        this.isLoading = false;
        this.service.getBookingDetails(this.data.event.data.id).subscribe((d: any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
          this.isLoading = true;
          console.log(decData.data.items[0])
          this.user = decData.data.items[0];
          this.getImg(this.user.patient_user_id.photo_media_id);
        });
      }
    }
  }

  getImg(id) {
    if ((typeof id) == 'number') {
      this.service.getImgId(id).subscribe((d: any) => {
        console.log(d.data.items[0].url)
        this.user.patient_user_id.photo_media_id = d.data.items[0].url;
      })
    } else {
      this.user.patient_user_id.photo_media_id = 'https://terapeuta.mindhelp.mx/assets/img/profile.jpg';
    }
  }
  returnStatus(s) {
    let status = s;
    switch (s) {
      case 'scheduled_appointment':
        status = "Cita Agendada"; break;
      case 'appointment_in_progress':
        status = "En progreso"; break;
      case 'to_be_confirmed_by_therapist':
        status = "Por Confirmar"; break;
      case 'out_of_office':
       status = "Fuera de Oficina"; break;
      default:
        status = s; break;
    }
    return status
  }

}
